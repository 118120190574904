import { Inject, Injectable } from '@angular/core';
import { RoleModel, RoleRequestModel } from '../_models/role.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';
import { UserModel } from '../_models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _httpService: HttpMethodsService) {}

  login(username: string, password: string) {
    return this._httpService.post('login', {
      username,
      password,
    });
  }

  me(): Observable<UserModel> {
    return this._httpService.get('auth');
  }

  forgot(email: string) {
    console.log(email)
    return this._httpService.post('auth_forgot', { email });
  }

  reset(hash: string, password: string) {
    return this._httpService.post('auth_reset', { hash, password });
  }

}
